import VideoPlayer from './components/Player/VideoPlayer';
import './App.css';

// const mp4Src =
//   'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4';

const hslSrc =
  'https://d3qm8io4a04o8f.cloudfront.net/AUTO_ABR/USC003_ShortFilmClassTrailer.mpd';

function App() {
  return (
    <div className="App">
      <VideoPlayer src={hslSrc} autoPlay={false} />
    </div>
  );
}

export default App;
